import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import fetchDojoTerminals from '../api/fetchDojoTerminals';
import makeDojoPayment from '../api/makeDojoPayment';
import { useAppContext } from '../context/App';
import { DojoConfig } from '../types/types';
import { setDojoKioskTransaction } from '../api/setDojoKioskTransaction';
import { setPlaceOrder } from '../api/placeOrder';
import { useGetCart } from './useGetCart';
import { cancelPlaceOrder } from '../api/cancelPlaceOrder';

interface TerminalData {
    id: string;
    name: string;
    status: string;
}

interface TerminalList {
    terminals: TerminalData[]
}

interface PaymentResponse {
    requestId: string;
    location: string;
    userMessage: string;
}

interface TransactionStatusResponse {
    amountBase: string;
    amountCashback: string;
    amountGratuity: string;
    amountTotal: string;
    applicationId: string;
    applicationLabel: string;
    authCode: string;
    cardSchemeName: string;
    cardHolderVerificationMethod: string;
    currency: string;
    dateOfExpiry: string;
    dateOfStart: string;
    location: string;
    notifications: string[];
    paymentMethod: string;
    primaryAccountNumber: string;
    primaryAccountNumberSequence: string;
    receiptLines: {
        MERCHANT: Array<{
            format: string[];
            type: string;
            value: string;
        }>;
        CUSTOMER: Array<{
            format: string[];
            type: string;
            value: string;
        }>;
    };
    requestId: string;
    transactionId: string;
    transactionNumber: string;
    transactionResult: string;
    transactionTime: string;
    transactionType: string;
    userMessage: string;
}

export const useDojo = () => {
    const { 
        orderId,
        setOrderId,
        setRequestId, 
        requestId,
        setPaymentInformation,
        addError
    } = useAppContext();

    const navigate = useNavigate();
    const queryClient = useQueryClient(); 
    const cart = useGetCart();

    const accountName = 'sc410y120000';
    const terminalState = 'AVAILABLE';
    const env = 'test.connect.paymentsense.cloud';

    const dojoConfig: DojoConfig = {
        url: `https://${accountName}.${env}`,
        key: 'd54c538a-9d09-46e0-a4ae-a45ad15031c3',
        username: 'DevTest',
        houseId: 'SC410Y12',
        installerId: '1',
        currentTerminalId: '21110772',
    };

    // 21110031 Fidelity
    // 21110772 Stratus21 home

    const headers = new Headers();
    const basicAuth = btoa(`${dojoConfig.username}:${dojoConfig.key}`);

    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', 'Basic ' + basicAuth);
    headers.append('Software-House-ID', dojoConfig.houseId);
    headers.append('Installer-ID', dojoConfig.installerId);
    headers.append('Accept', 'application/connect.v2+json');

    // Fetch list of terminals
    const { data: terminals } = useQuery<TerminalList, Error>({
        queryKey: ["terminals", terminalState],
        queryFn: () => fetchDojoTerminals(dojoConfig, headers, terminalState),
        onSuccess: (data) => {
            if(data?.terminals && !data?.terminals.length) {
                addError('No available terminals found.');
            }
        },
        onError: (err) => {
            addError('dojoFetchTerminals', null, "Error fetching terminals");
        }
    });

    // Make payment API - Pass in terminalId and amount
    const makePaymentApi = useMutation<PaymentResponse, Error, {
        amount: number 
    }>({
        mutationFn: ({ amount }) => makeDojoPayment(amount, dojoConfig, headers),
        onSuccess: (data) => {
            if(data.userMessage) {
                addError('Dojo transactions', null, `makeDojoPayment - ${data.userMessage}`);
            }
            
            if(data?.requestId) {
                setRequestId(data?.requestId ?? ''); // Store the requestId
                navigate('/payment');
            }
        },
        onError: (err) => {
            addError('dojoMakePayment', null, "Error making payment");
        }
    });

    // This validates order before making dojo payment
    const placeOrderApi = useMutation({
        mutationFn: setPlaceOrder,
        onSuccess: (data) => {
            addError('placeOrder', data);

            if(data.errorHandling.success) {
                setOrderId(data?.order?.order_id);
                makePaymentApi.mutate({
                    amount: cart?.cart?.prices?.grand_total.value ?? 0.00
                });
            }
        },
        onError: (err) => {
            addError('placeOrder', null, "Place order api failed");
        }
    });

    const placeOrder = (redirect?: string) => {
        placeOrderApi.mutate({
            cartID: cart?.cart?.id ?? ''
        });
    }
    
    const setDojoTransactionApi = useMutation({
        mutationKey: ["dojoTransaction"],
        mutationFn: setDojoKioskTransaction,
        onSuccess: (data) => {
            addError('setDojoKioskTransaction', data);
            
            if (data?.errorHandling?.success) {
                setPaymentInformation({
                    orderType: cart?.cart?.orderType ?? '',
                    orderNumber: data?.kioskOrderNumber,
                    fullReceipt: data?.fullReceipt,
                    tableNumber: cart?.cart?.seating ? cart?.cart?.seating?.label : ''
                });

                // Make stratus21 call and send data, then redirect and remove transaction result data ready for next order
                queryClient.setQueryData(['transactionStatus'], (oldData: any) => {
                    return {
                        "location": "",
                        "notifications": []
                    };
                });
                setRequestId('');
                setOrderId('');

                navigate('/confirmed');
            }
        },
        onError: (err) => {
            addError('setDojoKioskTransaction', null, "Error setDojoTransactionApi");
        }
    });

    const setCancelPlacedOrderApi = useMutation({
        mutationFn: cancelPlaceOrder,
        onSuccess: (data) => {
            console.log(data, "setCancelPlacedOrderApi");
            addError('CancelPlaceOrder', data);

            if(data?.errorHandling?.success) {
                setOrderId('');
            }
        },
        onError: (err) => {
            console.log(err, "setCancelPlacedOrderApi err");
            addError('CancelPlaceOrder', null, "Error setCancelPlacedOrderApi");
        }
    });
    
    // Function to poll transaction status every second
    const { data: transactionStatus } = useQuery<TransactionStatusResponse>(
        [
            "transactionStatus", 
            dojoConfig.currentTerminalId, 
            requestId
        ],
        async () => {
            const endpoint = `pac/terminals/${dojoConfig.currentTerminalId}/transactions/${requestId}`;
            const response = await fetch(`${dojoConfig.url}/${endpoint}`, {
                method: 'GET',
                headers: headers,
            });

            if (!response.ok) { 
                console.log("transactions response fail");
                throw new Error('Error fetching transaction status');
            }
            return await response.json();
        },
        {
            enabled: !!requestId,  // Only start polling if requestId is available
            refetchInterval: 1000,  // Poll every 1 second
            onSuccess: (data) => {
                console.log('Transaction Status:', data);

                if(data.transactionResult === 'SUCCESSFUL') {
                    if(orderId) {
                        setDojoTransactionApi.mutate({
                            orderId: orderId,
                            transactionId: data.transactionId
                        });
                    }
                }

                if (
                    ['CANCELLED', 'DECLINED', 'VOID', 'UNSUCCESSFUL'].includes(data.transactionResult) && 
                    orderId
                ) {
                    setCancelPlacedOrderApi.mutate({
                       orderId: orderId
                    });
                    setRequestId('');
                }
            },
            onError: (err) => {
                addError('DojoTransactions', err, 'Error fetching transaction status');
            }
        }
    );

    return {
        terminals,
        makePaymentApi,
        placeOrder,
        transactionStatus,
    };
}
