import { useQuery } from "@tanstack/react-query";
import fetchStoreBranding from "../api/fetchStoreBranding";

interface ThemeObject {
    businessInfo: string
    collection: string
    delivery: string
    deliveryMinSpend: string
    description: string
    eatIn: string
    id: string
    storeViewCode: string
    name: string
    specialOffers: string
    status: {
        message: string
        state: string
    }
    theme: {
        primaryColour: string
        secondaryColour: string
        tertiaryColour: string
        highlightColour: string
        font: string
        logo: string
        image: string
        kioskStartScreenImage: string
    }
}

interface ThemeInterface {
    items: ThemeObject[]
}

export const useGetTheme = () => {
    const { data } = useQuery<ThemeInterface>({
        queryFn: fetchStoreBranding,
        queryKey: ["branding"],
        staleTime: (1000 * 60) * 60 * 24 // 24 Hours
    });

    const foundStore = data?.items && data?.items[0];
    const theme = data?.items && data?.items[0]?.theme;

    const logo = theme?.logo ?? 'https://placehold.co/100x100';
    const primaryColor = theme?.primaryColour ?? '#1F2245';
    const secondaryColor = theme?.secondaryColour ?? '#36B5A6';
    const kioskStartScreenImage = theme?.kioskStartScreenImage ?? 'https://placehold.co/1600x900/black/white';
    const storeId = foundStore?.id ?? '';
    // const kioskHeroImage = theme?.kioskHeroImage ?? 'https://placehold.co/900x470';
    const kioskHeroImage = 'https://placehold.co/900x470/black/white' 
    
    localStorage.setItem('storeViewCode', foundStore?.storeViewCode ?? '');

    return {
        logo,
        primaryColor,
        secondaryColor,
        kioskStartScreenImage,
        kioskHeroImage,
        storeId
    }
};
