import { FC } from "react";
import { ProductWrapper } from "../context/Product";
import { CartItem, MenuProduct } from "../types/types";
import AddToCartButton from "./Menu/AddToCartButton"; 
import UpdateCartButton from "./Menu/UpdateCartButton";

import Modal from "./Modal";
import ProductInputs from "./ProductModal/ProductInputs";

interface RemoveItemModalInterface {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    productDetails: MenuProduct;
    editData?: CartItem | undefined;
}

export const ProductModal: FC<RemoveItemModalInterface> = ({ 
    productDetails,
    editData,
    show, 
    setShow
 }) => {

    if(!productDetails?.name) return null;

    return (
        <Modal
            show={show}
            setShow={setShow}
            nopadding
            width={860}
        >
            <ProductWrapper productDetails={productDetails}>
                {(
                    (productDetails?.productImages && productDetails?.productImages.length) &&
                    !productDetails?.productImages[0].src.includes('placeholder')
                ) ? (
                    <div
                        className="w-full h-[470px] relative"
                        style={{
                            backgroundImage: `url(${productDetails?.productImages[0].src})`,
                            backgroundSize: "cover"
                        }}
                    >
                        <img src="hero-curve.png" alt="Curve" className="absolute -bottom-[1px]"/>
                    </div>
                ) : null}

                <div className="flex flex-col px-14 overflow-y-auto pt-8 pb-8 max-h-[1200px]">
                    <div className="mb-10">
                        <div className="flex justify-between mb-3">
                            <p className="font-bold text-4xl">{productDetails?.name}</p>
                            <p className="font-bold text-4xl">{productDetails?.priceFormatted}</p>

                            {!productDetails?.inStock ? (<span className="text-red-500 my-2 text-sm">Out of stock</span>) : null}
                        </div>

                        <p className="font-bold mb-3">{productDetails.calorificContent}</p>
                        <p className="">{productDetails?.longDescription}</p>

                        {/* Allergy */}
                        {productDetails?.allergyShort || productDetails?.allergyLong ? (
                            <p className="mt-6"><strong>Allergy Information:</strong></p>
                        ) : null}

                        {productDetails?.allergyShort && (
                            <p className="mb-2">{productDetails?.allergyShort}</p>
                        )}

                        {productDetails?.allergyLong && (
                            <p className="mb-2">{productDetails?.allergyLong}</p>
                        )}

                        {productDetails?.allergens && productDetails?.allergens.length ? (
                            <div className="flex flex-wrap mt-6">
                                {productDetails?.allergens.map((allergen) => {
                                    return (
                                        <div className="tag bg-tag text-tag mr-2 mb-2">{allergen.value}</div>        
                                    );
                                })}
                            </div>
                        ) : null}
                    </div>
                    
                    <ProductInputs
                        editData={editData}
                    />
                </div>

                <div 
                    className="bg-slate-100 flex px-14 py-8"
                >
                    <button
                        className="btn w-1/2 mr-8"
                        style={{
                            backgroundColor: '#CBD5E1'
                        }}
                        onClick={() => {
                            setShow(false);
                        }}
                    >
                        Cancel
                    </button>

                    {!editData ? <AddToCartButton setShow={setShow} /> : <UpdateCartButton editData={editData} setShow={setShow} />}
                </div>
            </ProductWrapper>
        </Modal>
    )
};

export default ProductModal;