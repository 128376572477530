import { useNavigate } from "react-router-dom";

import BackButton from "../components/BackButton";
import CancelButton from "../components/CancelButton";
import HelpButton from "../components/HelpButton";
import Loyalty from "../components/Cart/Loyalty/Loyalty";
import Totals from "../components/Cart/Totals";
import CartItems from "../components/Cart/CartItems";
import CartButtons from "../components/Cart/CartButtons";
import TimeoutModal from "../components/Modals/TimeoutModal";
import ToastHandler from "../components/Toasts/ToastHandler";

function Cart() {
    const navigate = useNavigate();

    return (
        <>
            <div className="bg-white py-11 mb-16">
                <h2 className="text-5xl text-black font-bold text-center">Your Order</h2>
            </div>

            <div className="px-[75px]">
                <div className="flex justify-between mb-8">
                    <div>
                        <BackButton 
                            onClick={() => {
                                navigate("/menu");
                            }} 
                        />
                    </div>
                    
                    <div className="flex">
                        <div className="mr-8">
                            <CancelButton />
                        </div>

                        <HelpButton />
                    </div>
                </div>

                <CartItems />
                <Loyalty />
                <Totals />
            </div>

            {/* <VoucherPopups /> */}
            <ToastHandler page="cart" />
            <CartButtons />
            <TimeoutModal />
        </>
    );
}


export default Cart;