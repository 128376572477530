import { FC } from 'react';

import { useDojo } from '../../hooks/useDojo';
import { useGetCart } from '../../hooks/useGetCart';
import { useGetTheme } from '../../hooks/useGetTheme';
import { useIsMutating } from '@tanstack/react-query';

export const CartButtons: FC = () => {
    const cart = useGetCart();
    const theme = useGetTheme();
    const isMutating = useIsMutating();

    const {
        placeOrder
    } = useDojo();

    const isDisabled = !cart?.cart?.items || !cart?.cart?.items.length;

    return (
        <div className="shadow-lg bg-white absolute bottom-0 left-auto right-auto w-[900px] mx-auto px-[75px] py-8 flex">
            <div className="flex flex-col w-1/2">
                <p className="text-4xl mb-2">Total</p>
                <p className="text-5xl font-bold">£{cart?.cart.prices?.grand_total?.value.toFixed(2) || '0.00'}</p>
            </div>

            <div className="w-1/2 flex items-center">
                <button 
                    className={`w-full py-5 text-3xl text-white rounded-md font-bold ${isDisabled ? 'opacity-20' : null}`}
                    style={{
                        backgroundColor: theme?.primaryColor
                    }}
                    onClick={() => {
                        if(!isMutating) {
                            placeOrder();
                        }
                    }}
                    disabled={isDisabled}
                >
                    Pay
                </button>
            </div>
        </div>
    );
};

export default CartButtons;
