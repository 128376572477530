import { FC, useState } from "react";
import VoucherModal from "./VoucherModal";
import { useGetTheme } from "../../../hooks/useGetTheme";
import { useGetCart } from "../../../hooks/useGetCart";

export const Vouchers: FC = () => {
    const theme = useGetTheme();
    const cart = useGetCart();
    const showVouchers = cart?.cart?.loyaltyActive;
    const [voucherModal, setVoucherModal] = useState(false);

    if(!showVouchers || !cart?.cart?.applicableVouchers.length) return null;

    return (
        <>
            <div
                className="py-5 px-8 mb-4 flex justify-between rounded-lg items-center"
                style={{
                    backgroundColor: '#C4EDE9'
                }}
            >
                <p className="text-2xl"><strong>You have vouchers available to use.</strong><br/>
                Don't miss out!</p>
                <div>
                    <button
                        className="btn"
                        style={{
                            backgroundColor: theme.secondaryColor,
                            color: theme.primaryColor
                        }}
                        onClick={() => {
                            setVoucherModal(true);
                        }}
                    >
                        View&nbsp;Vouchers
                    </button>
                </div>
            </div>

            <VoucherModal show={voucherModal} setShow={setVoucherModal} />
        </>
    )
};

export default Vouchers;