interface ApiResponse {
    errorHandling?: any;
}

export async function fetcher(
    query: string,
    cleanup: Function
) {

    // https://s21preorderbackend.fidelityportal.co.uk/graphql
    // https://kioskqa.fidelityportal.co.uk/graphql
    const storeViewcode = localStorage.getItem('storeViewCode');

    const response = await fetch(
        process.env.NEXT_PUBLIC_MAGENTO_API_URL ?? 'https://s21preorderbackend.fidelityportal.co.uk/graphql',
        {
            method: "POST",
            body: JSON.stringify({ query }),
            headers: { 
                "Content-Type": "application/json",
                "Store": storeViewcode ? storeViewcode : ''
            },
        }
    );

    if (!response.ok) {
        // Log the error response to Sentry if response status is not OK
        // Sentry.captureException(new Error(`API Error: ${response.status} ${response.statusText}`));
        return { error: `API Error: ${response.status} ${response.statusText}` };
    }

    const jsonResponse = await response.json();

    if (jsonResponse.errors) {
        // Log any GraphQL errors to Sentry
        // Sentry.captureException(new Error(`API Response Error: ${JSON.stringify(jsonResponse.errors)}`));
        return { error: jsonResponse.errors };
    }

    // Handle specific error messages from `errorHandling`
    if (jsonResponse.data) {
        Object.entries(jsonResponse.data as Record<string, ApiResponse>).forEach(([key, value]) => {
            if (value?.errorHandling && !value.errorHandling.success) {
                value.errorHandling.message.forEach((message: any) => {
                    const errorMessage = message.message;
                    const allowedErrorMessages = [
                        'The request is allowed for logged in customer',
                        'The account sign-in was incorrect or your account is disabled',
                        'Minimum of different classes of characters',
                        'Verify the code and try again',
                        'Cannot reset the customer’s password',
                        'A customer with the same email address already exists',
                        'The current user cannot perform operations',
                    ];

                    if (!allowedErrorMessages.some((msg) => errorMessage.includes(msg))) {
                        // Log to Sentry if not an allowed message
                        // Sentry.captureException(new Error(`API Error Handling in ${key}: ${errorMessage}`));
                    }
                });
            }
        });
    }

    // Pass only clean data to `cleanup` if no errors
    return cleanup(jsonResponse);
}