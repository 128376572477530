import { cartInfo } from '../queries/cart';
import { ERROR_HANDLING } from '../queries/errorHandling';
import { fetcher } from './fetcher';

// Clean up server response (It was returning data.data.cart.cart etc)
function responseCleanup(data: any) {
    return data.data.stratus21SetOrderType;
}

interface OrderTypeApiInterface {
    cartID: string,
    type: string,
    timeSlot: "ASAP",
    tableNumber: string,
    storeId: string
}

export async function setOrderType(payload : OrderTypeApiInterface) {
    return await fetcher(
        `
            mutation SetOrderType {
                stratus21SetOrderType(
                    cartId: "${payload.cartID}",
                    type: "${payload.type}",
                    timeSlot: "${payload.timeSlot}",
                    tableNumber: "${payload.tableNumber}",
                    storeId: "${payload.storeId}"
                ) {
                    ${cartInfo}
                    ${ERROR_HANDLING}
                }
            }
        `,
        responseCleanup
    );
}