import { FC } from 'react';
import { useDojo } from '../../hooks/useDojo';
import { useGetTheme } from '../../hooks/useGetTheme';
import { useIsMutating } from '@tanstack/react-query';

export const RetryButton: FC = () => {
    const theme = useGetTheme();
    const isMutating = useIsMutating();

    const {
        placeOrder
    } = useDojo();

    return (
        <>
            <button 
                className="btn btn-small-spacing text-white"
                style={{
                    backgroundColor: theme.secondaryColor,
                    color: theme.primaryColor
                }}
                onClick={() => {
                    if(!isMutating) {
                        placeOrder();
                    }
                }}
            >
                Retry
                <svg 
                    width="24" 
                    height="24" 
                    viewBox="0 0 24 24" 
                    fill="none" 
                    xmlns="http://www.w3.org/2000/svg" 
                    className='ml-2'
                    style={{
                        color: theme.primaryColor
                    }}
                >
                    <path d="M12 24C8.65 24 5.8125 22.8375 3.4875 20.5125C1.1625 18.1875 0 15.35 0 12C0 8.65 1.1625 5.8125 3.4875 3.4875C5.8125 1.1625 8.65 0 12 0C13.725 0 15.375 0.3565 16.95 1.0695C18.525 1.7815 19.875 2.8 21 4.125V1.5C21 1.075 21.144 0.7185 21.432 0.4305C21.719 0.1435 22.075 0 22.5 0C22.925 0 23.281 0.1435 23.568 0.4305C23.856 0.7185 24 1.075 24 1.5V9C24 9.425 23.856 9.781 23.568 10.068C23.281 10.356 22.925 10.5 22.5 10.5H15C14.575 10.5 14.219 10.356 13.932 10.068C13.644 9.781 13.5 9.425 13.5 9C13.5 8.575 13.644 8.2185 13.932 7.9305C14.219 7.6435 14.575 7.5 15 7.5H19.8C19 6.1 17.9065 5 16.5195 4.2C15.1315 3.4 13.625 3 12 3C9.5 3 7.375 3.875 5.625 5.625C3.875 7.375 3 9.5 3 12C3 14.5 3.875 16.625 5.625 18.375C7.375 20.125 9.5 21 12 21C13.725 21 15.319 20.5435 16.782 19.6305C18.244 18.7185 19.3375 17.5 20.0625 15.975C20.1875 15.7 20.394 15.469 20.682 15.282C20.969 15.094 21.2625 15 21.5625 15C22.1375 15 22.569 15.2 22.857 15.6C23.144 16 23.175 16.45 22.95 16.95C22 19.075 20.5375 20.781 18.5625 22.068C16.5875 23.356 14.4 24 12 24Z" fill="currentColor"/>
                </svg>
            </button>
        </>
    );
};

export default RetryButton;