import { FC } from "react";
import { useAppContext } from "../../context/App";
import ToastAuthenticated from "./ToastAuthenticated";
import ToastLoyalty from "./ToastLoyalty";
import ToastProductAdded from "./ToastProductAdded";
import ToastProductRemoved from "./ToastProductRemoved";

const ToastHandler: FC<{page: "menu" | "cart"}> = ({ page }) => {
    const { toasts } = useAppContext();

    const toastPosition = page === "menu" ? 'bottom-[225px] left-[269px] right-[60px]' : 'bottom-[200px] left-[75px] right-[75px]';
    
    return (
        <div className={`absolute ${toastPosition}`}>
            {toasts.map((toast) => {
                if(toast.type === 'voucher') {
                    //return <ToastVoucher key={toast.id} text={toast.text} />
                }

                if(toast.type === 'loyalty') {
                    return <ToastLoyalty key={toast.id} text={toast.text} />
                }

                if(toast.type === 'authenticated') {
                    return <ToastAuthenticated key={toast.id} text={toast.text} />
                }
                
                if(toast.type === 'productAdded') {
                    return <ToastProductAdded key={toast.id} text={toast.text} />
                }

                if(toast.type === 'productRemove') {
                    return <ToastProductRemoved key={toast.id} text={toast.text} />
                }
            })}
        </div>
    );
};

export default ToastHandler;
