import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FC } from "react";
import { applyDiscount } from "../../../api/applyDiscount";
import { useGetCart } from "../../../hooks/useGetCart";
import { useGetTheme } from "../../../hooks/useGetTheme";
import { useAppContext } from "../../../context/App";

export const Points: FC = () => {
    const cart = useGetCart();
    const theme = useGetTheme();
    const queryClient = useQueryClient();
 
    const {
        addError,
        addToast
    } = useAppContext();

    const applyDiscountApi = useMutation({
        mutationFn: applyDiscount,
        onSuccess: (data) => {
            if (data?.errorHandling?.success) {
                if(data?.cart?.appliedLoyaltyPoints) {
                    addToast((data?.cart?.rewardBalance / 100).toFixed(2), "loyalty");
                }

                queryClient.setQueryData(['cart'], (oldData: any) => {
                    return {
                        ...oldData,
                        cart: data.cart
                    };
                });
            }

            addError('PaymentIntentionOrderTcp', data);
        },
        onError: (err) => {
            addError('PaymentIntentionOrderTcp', null, "Error PaymentIntentionOrderTcp failed");
        }
    });

    const priceToUSFormatting = (price: number) => {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    
    const rewardPoints = Number(cart?.cart?.rewardBalance) ?? 0;
    const rewardPointsFormatted = `${priceToUSFormatting(rewardPoints)}pts`;
    const rewardPriceFormatted = `(£${(rewardPoints / 100).toFixed(2)})`;

    const voucherIDs = cart?.cart.appliedVouchers?.map(voucher => voucher.voucherIdentifierRoleId) ?? [];

    const isLoyaltyUser = cart?.cart?.loyaltyActive;
    
    if(!isLoyaltyUser) return null;

    return (
        <div 
            className="py-5 px-8 flex justify-between rounded-lg items-center mb-4"
            style={{
                backgroundColor: '#DCDDEF'
            }}
        >
            <p className="text-2xl">
                <strong>Reward Points Available!</strong><br/> {`${rewardPointsFormatted}`} <strong>{rewardPriceFormatted}</strong>
            </p>

            <div>
                {rewardPoints >= 1 ? (
                    <button
                        className="btn"
                        onClick={()=> {
                            applyDiscountApi.mutate({
                                cartId: cart?.cart.id ?? '',
                                applyPoints: !cart?.cart.isLoyaltyApplied,
                                voucherIDs: voucherIDs
                            });
                        }}
                        style={{
                            backgroundColor: theme.primaryColor,
                            color: '#FFFFFF'
                        }}
                    >
                        {!cart?.cart.isLoyaltyApplied ? (<>Redeem</>) : (<>Unredeem</>)} Points
                    </button>
                ) : null}
            </div>
        </div>
    );
}

export default Points;