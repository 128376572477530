import { useQuery } from '@tanstack/react-query';
import fetchDojoTerminals from '../api/fetchDojoTerminals';
import { useAppContext } from '../context/App';
import { DojoConfig } from '../types/types';

interface TerminalData {
    currency: string;
    location: string;
    status: string;
    tid: string;
}

interface TerminalList {
    terminals: TerminalData[]
}
export const useGetTerminals = () => {
    const {
        addError
    } = useAppContext();

    const accountName = 'sc410y120000';
    const terminalState = 'AVAILABLE';
    const env = 'test.connect.paymentsense.cloud';

    const dojoConfig: DojoConfig = {
        url: `https://${accountName}.${env}`,
        key: 'd54c538a-9d09-46e0-a4ae-a45ad15031c3',
        username: 'DevTest',
        houseId: 'SC410Y12',
        installerId: '1',
        currentTerminalId: '',
    };

    const headers = new Headers();
    const basicAuth = btoa(`${dojoConfig.username}:${dojoConfig.key}`);

    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', 'Basic ' + basicAuth);
    headers.append('Software-House-ID', dojoConfig.houseId);
    headers.append('Installer-ID', dojoConfig.installerId);
    headers.append('Accept', 'application/connect.v2+json');

    // Fetch list of terminals
    const { data: terminals } = useQuery<TerminalList, Error>({
        queryKey: ["terminals", terminalState],
        queryFn: () => fetchDojoTerminals(dojoConfig, headers, terminalState),
        onSuccess: (data) => {
            if(data?.terminals && !data?.terminals.length) {
                addError('No available terminals found.');
            }
        },
        onError: (err) => {
            addError('dojoFetchTerminals', null, "Error fetching terminals");
        }
    });

    return terminals;
}
