import { useState } from 'react';

import LoginForm from '../components/Modals/Admin/LoginForm';
import PinModal from '../components/Modals/Admin/PinModal';
import AdminModal from '../components/Modals/Admin/AdminModal';

function AdminScreens() {
    const [showLogin, setShowLogin] = useState(false);
    const [showAdminModal, setAdminModal] = useState(false);
    const [show, setShow] = useState(false);

    return (
        <>
            <button 
                onClick={()=>{
                    // Show Pin Modal
                    // setShow(true);

                    // Show Login
                    //setShowLogin(true);

                    // Show Admin UI
                    setAdminModal(true);
                }} 
                className='absolute left-0 top-0 w-[45px] h-[45px] z-10'
            ></button>

            <LoginForm show={showLogin} setShow={setShowLogin} />
            <PinModal show={show} setShow={setShow} />
            <AdminModal show={showAdminModal} setShow={setAdminModal} />
        </>
    )
}

export default AdminScreens;