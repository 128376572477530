import { FC, useEffect, useState } from "react";
import { useGetTheme } from "../../hooks/useGetTheme";

export const ToastAuthenticated: FC<{text: string}> = ({text}) => {
    const theme = useGetTheme();

    const [isFadingOutLoyalty, setIsFadingOutLoyalty] = useState(false);
    const [showToast, setShowToast] = useState(false);

    useEffect(() => {
            setShowToast(true);

            // Start fade-out after 4.5 seconds
            const fadeTimer = setTimeout(() => setIsFadingOutLoyalty(true), 4000);

            // Hide completely after 5 seconds
            const hideTimer = setTimeout(() => {
                setShowToast(false);
                setIsFadingOutLoyalty(false); // Reset fade-out for next activation
            }, 5000);

            return () => {
                clearTimeout(fadeTimer);
                clearTimeout(hideTimer);
            };
    }, []);

    return (
        <>
            {showToast && (
                <div
                    className={`rounded-lg flex border border-solid border-toast overflow-hidden mx-auto w-full shadow-lg mt-4 transition-all ${
                        isFadingOutLoyalty ? 'fade-out' : ''
                    }`}
                >
                    <div
                        className="px-6 py-5"
                        style={{
                            backgroundColor: theme.secondaryColor
                        }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#ffffff" className="w-10 h-10"><path d="m424-296 282-282-56-56-226 226-114-114-56 56 170 170Zm56 216q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                    </div>

                    <div className="px-8 py-4 bg-white w-full flex items-center">
                        <p>Your vouchers and points have been added to your order.</p>
                    </div>
                </div>
            )}
        </>
    );
};

export default ToastAuthenticated;
