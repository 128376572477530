import { fetcher } from "./fetcher";
import { ERROR_HANDLING } from '../queries/errorHandling';

function cleanup(data: any) {
    return data.data.stratus21Categories;
}

interface CategoriesProductsInterface {
    storeId: string,
    orderType: string
}

export default async function fetchCategoriesProducts(payload: CategoriesProductsInterface) {
    let fetchCategoriesProductsQuery = `query GetCategories {
        stratus21Categories(
            storeId: "${payload.storeId}",
            orderType: "${payload.orderType}"
        ) {
            searchTerms
            items {
                id
                name
                description
                products {
                    inStock
                    active
                    allergyLong
                    allergyShort
                    allergens {
                        value
                    }
                    calorificContent
                    id
                    sku
                    imageThumbnail
                    longDescription
                    name
                    price
                    priceFormatted
                    productImages {
                        alt
                        src
                    }
                    purchaseOptions {
                        uid
                        id
                        type
                        maxLimit
                        required
                        name
                        label
                        values {
                            id
                            label
                            minLimit
                            maxLimit
                            pricePerUnit
                            pricePerUnitFormatted
                            uid
                            value
                        }
                    }
                    shortDescription
                }
                active
                status
            }
            ${ERROR_HANDLING}
        }
    }`

    return await fetcher(fetchCategoriesProductsQuery, cleanup);
}