import { FC, useState } from "react";
import { useGetTables } from "../../hooks/useGetTables";
import { useGetTheme } from "../../hooks/useGetTheme";
import { Seat } from "../../types/types";

interface SelectTableNumberInterface {
    selectedOrderType: string;
    setSelectedTableNumber: Function;
}
 
export const SelectTableNumber: FC<SelectTableNumberInterface> = ({
    selectedOrderType,
    setSelectedTableNumber
}) => {
    const theme = useGetTheme();
    const tables = useGetTables();
    const [isFocused, setIsFocused] = useState(false);
    const [inputValue, setInputValue] = useState<string>(''); // Manage input value through state
    const [hasSelected, setHasSelected] = useState<boolean>(false);

    // Filter the tables based on the inputValue
    const filteredTables = !hasSelected ? tables?.seating?.filter((table: Seat) => table.label.toLowerCase().includes(inputValue.toLowerCase())) ?? [] : tables?.seating ?? [];

    if(selectedOrderType !== 'inside') return null;

    return (
        <div className="flex flex-col mx-auto text-center mb-10">
            <p 
                className="text-[48px] font-bold block"
                style={{
                    color: theme.primaryColor
                }}
            >
                Select your table number:
            </p>
            
            <div className="relative mt-14 flex flex-col items-center">
                <input 
                    type="text" 
                    name="search"
                    className="border border-inputGray py-5 px-6 text-4xl outline-none w-[395px]"
                    placeholder="Enter table number"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setInputValue(e.target.value);
                        setHasSelected(false);
                    }}
                    value={inputValue}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setTimeout(() => setIsFocused(false), 100)}
                />

                {isFocused ? (
                    <div className="absolute top-full w-[395px] bg-gray-100 pt-2 pb-4 p-5 max-h-[420px] overflow-auto">
                        {filteredTables.length ? (
                            <>
                                <button
                                    className="w-full text-left pt-2 leading-none min-h-[37px]"
                                    onClick={()=> {
                                        setHasSelected(false);
                                        setInputValue('');
                                    }}
                                >
                                    Select a table...
                                </button>

                                {filteredTables.map((seat: Seat, index: number) => {
                                    return (
                                        <button
                                            className="bg-gray-100 border-t w-full text-left pt-2 mt-2 leading-none min-h-[37px]"
                                            key={`table-${index}`}
                                            onClick={() => {
                                                setHasSelected(true);
                                                setInputValue(seat.label);
                                                setSelectedTableNumber({
                                                    value: seat.seatValue,
                                                    label: seat.label
                                                });
                                            }}
                                        >
                                            {seat.label}
                                        </button>
                                    )
                                })}
                            </>
                        ) : (
                            <button
                                className="w-full text-left mt-2"
                                key={`table-notfound`}
                                disabled={true}
                            >
                                No tables found.
                            </button>
                        )}
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default SelectTableNumber;