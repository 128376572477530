import { useQuery } from "@tanstack/react-query";
import fetchTables from "../api/fetchTables";
import { TableInterface } from "../types/types";
import { useAppContext } from "../context/App";
import { useGetTheme } from "./useGetTheme";

export const useGetTables = () => {
    const theme = useGetTheme();

    const payload = {
        storeId: theme?.storeId
    };

    const {
        addError
    } = useAppContext();
    
    const { data: tables } = useQuery<TableInterface>({
        queryKey: ["tables", payload],
        queryFn: () => fetchTables(payload),
        onSuccess: (data) => {
            addError('GetAvailableTimeslots', data);
        },
        onError: (err) => {
            addError('GetAvailableTimeslots', null, "Error table numbers fetch failed");
        }
    });

    return tables ?? {
        seating: []
    };
};
