import { FC, useEffect, useState } from "react";
import { useGetTheme } from "../../hooks/useGetTheme";

export const ToastProductAdded: FC<{text: string}> = ({text}) => {
    const theme = useGetTheme();

    const [isFadingOutLoyalty, setIsFadingOutLoyalty] = useState(false);
    const [showToast, setShowToast] = useState(false);

    useEffect(() => {
            setShowToast(true);

            // Start fade-out after 4.5 seconds
            const fadeTimer = setTimeout(() => setIsFadingOutLoyalty(true), 4000);

            // Hide completely after 5 seconds
            const hideTimer = setTimeout(() => {
                setShowToast(false);
                setIsFadingOutLoyalty(false); // Reset fade-out for next activation
            }, 5000);

            return () => {
                clearTimeout(fadeTimer);
                clearTimeout(hideTimer);
            };
    }, []);

    return (
        <>
            {showToast && (
                <div
                    className={`rounded-lg flex border border-solid border-toast overflow-hidden mx-auto w-full shadow-lg mt-4 transition-all ${
                        isFadingOutLoyalty ? 'fade-out' : ''
                    }`}
                >
                    <div
                        className="px-6 py-5"
                        style={{
                            backgroundColor: theme.secondaryColor
                        }}
                    >
                        <svg 
                            width="41" 
                            height="41" 
                            viewBox="0 0 41 41" 
                            fill="none" 
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-10"
                        >
                            <path d="M20.5002 0.493408C9.23016 0.493408 0.0834961 9.46073 0.0834961 20.5098C0.0834961 31.5588 9.23016 40.5261 20.5002 40.5261C31.7702 40.5261 40.9168 31.5588 40.9168 20.5098C40.9168 9.46073 31.7702 0.493408 20.5002 0.493408ZM30.7085 22.5114H22.5418V30.5179H18.4585V22.5114H10.2918V18.5081H18.4585V10.5016H22.5418V18.5081H30.7085V22.5114Z" fill="white"/>
                        </svg>
                    </div>

                    <div className="px-8 py-4 bg-white w-full flex items-center">
                        <p><strong>{text}</strong> added to order</p>
                    </div>
                </div>
            )}
        </>
    );
};

export default ToastProductAdded;
