import { FC, useState } from 'react';

import { useGetCategories } from '../../hooks/useGetCategories';

import { CartItem, MenuCategory, MenuProduct } from '../../types/types';

import ProductModal from '../ProductModal';
import RemoveItemButton from './RemoveItemButton';

interface CartItemInterface {
    key: number,
    item: CartItem,
    noMargBottom?: boolean
}

export const Item: FC<CartItemInterface> = ({ key, item, noMargBottom }) => {
    const categories = useGetCategories();
    const [showProductModal, setShowProductModal] = useState(false);

    const products = categories?.items && categories?.items.length ? categories?.items.flatMap((category: MenuCategory) => category.products) : [];
    const foundProduct = products.filter((product: MenuProduct) => product.id === item.product.id)[0];
    
    return (
        <div className={`flex ${noMargBottom ? null : 'mb-4'} w-full`}>
            <button 
                className="bg-white rounded-l-lg p-8 flex w-full"
                onClick={() => {
                    setShowProductModal(true);
                }}
            >
                {item.product.image.url ? (
                    <div className="min-w-[130px] h-[130px] overflow-hidden rounded-lg mr-7 relative">
                        <img 
                            src={item.product.image.url} 
                            alt={item.product.name}
                            className="absolute max-w-fit min-w-[130px] h-[130px]"
                        />
                    </div>
                ) : null}

                <div className="flex flex-col pr-11">
                    <h3 className="font-bold mb-2 text-2xl leading-none text-left">{item?.quantity == "1" ? null : `${item.quantity}x`} {item.product.name}</h3>
                    {/* <p 
                        className="mb-2 text-base leading-normal text-left"
                        dangerouslySetInnerHTML={{
                            __html: item.product.description.html
                        }}
                    >
                    </p> */}
                    
                    {item?.customizable_options && item?.customizable_options.length ? (
                        <>
                            {["", "Extras:"].map((heading, index) => {
                                const isRequired = index === 0; // First iteration is for required, second for optional
                                const options = item.customizable_options.filter(option => option.is_required === isRequired);

                                if (!options.length) return null;

                                return (
                                    <div key={heading}>
                                        {heading !== '' ? (
                                            <h2 className="font-bold text-xl leading-normal text-left">{heading}</h2>
                                        ) : null }

                                        {options.map(option => {
                                            let optionValues: any[] = [];

                                            return (
                                                <div
                                                    key={option.customizable_option_uid}
                                                    className="text-left text-product-instruction  text-xl"
                                                >
                                                    {/* <strong className="text-base leading-normal text-left font-bold mr-auto">
                                                        {option.label}
                                                    </strong> */}

                                                    {option.values.map(value => {
                                                        // Calculate quantity of duplicate values
                                                        const quantity = option.values.filter(
                                                            item => item.label === value.label
                                                        ).length;

                                                        let output = value.value;
                                                        let price = null;
                                                        let optionPrice = 0;

                                                        if (value.label) {
                                                            // Avoid duplicate output
                                                            if (!optionValues.includes(value.label)) {
                                                                optionValues.push(value.label);
                                                            } else {
                                                                return null;
                                                            }

                                                            output =
                                                                quantity > 1
                                                                    ? `x${quantity} ${value.label}`
                                                                    : value.label;
                                                            optionPrice = quantity * value?.price?.value;
                                                            price = value?.price?.value
                                                                ? `(+£${optionPrice.toFixed(2)})`
                                                                : null;
                                                        }

                                                        return (
                                                            <div
                                                                key={value.customizable_option_value_uid}
                                                                className="text-xl text-left mb-1"
                                                            >
                                                                {output} {price}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                        </>
                    ) : null}

                </div>

                <div className="ml-auto">
                    <p className="font-bold text-4xl">£{Number(item.prices.row_total_including_tax.value).toFixed(2)}</p>
                </div>
            </button>

            <RemoveItemButton productId={Number(item.id)} productName={foundProduct?.name}/>

            {foundProduct && foundProduct?.name ? (
                <ProductModal
                    productDetails={foundProduct}
                    editData={item}
                    show={showProductModal} 
                    setShow={setShowProductModal} 
                />
            ) : null}
        </div>
    );
}

export default Item;

