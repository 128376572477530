import { useEffect, useState } from "react";
import { useGetTheme } from "../hooks/useGetTheme";
import { useRestartKiosk } from "../hooks/restartKioskSession";
import { useAppContext } from "../context/App";

import { registerPlugin } from "@capacitor/core";

import Hero from "../components/Hero";
import LowPrinterPaperModal from "../components/Modals/LowPrinterPaperModal";
import useLight from "../hooks/useLight";

const Printer = registerPlugin('Printer') as any;

function Confirmed() {
    const theme = useGetTheme();
    const {restartKiosk} = useRestartKiosk();
    const [printerModal, setPrinterModal] = useState(false);
    const [hasPrinted, setHasPrinted] = useState(false);
    const {
        paymentInformation
    } = useAppContext();

    const {
        handleControlLamp
    } = useLight();

    const title = paymentInformation?.orderType === 'collection' ? 'Take Away Order Complete' : 'Order Placed';

    const instructions = paymentInformation?.orderType === 'collection' ? 'Please collect your order from the counter when your number is called.' : `We will deliver your order directly to your table as soon as it's ready.<br/><strong>Your table number is: ${paymentInformation?.tableNumber}</strong>`;

    // Set the green light
    useEffect(() => {
        handleControlLamp("Led-2");
    }, []);

    // Print receipt or show error
    useEffect(() => {
        if (hasPrinted || paymentInformation?.fullReceipt === '') return;

        const printReceipt = async () => {
            if (hasPrinted || paymentInformation?.fullReceipt === '') return;

            try {
                setHasPrinted(true);
                await Printer.print({ content: paymentInformation.fullReceipt });
            } catch (error) {
                setHasPrinted(true);
                console.error('Error printing:', error);
                setPrinterModal(true);
            }
        };

        printReceipt();
    }, [paymentInformation, hasPrinted]);

    // Auto restart after 5 seconds
    useEffect(() => {
        if(hasPrinted && !printerModal)
        setTimeout(() => {
            restartKiosk();
        }, 5000);
    }, [hasPrinted])

    return (
        <>
            <Hero alt />

            <p className="font-bold text-[50px] text-center mt-[83px] mb-12 mx-[260px] leading-snug">{title}</p>

            <p className="mb-6 text-center text-3xl">Your order number is:</p>

            <div 
                className="w-[350px] h-[350px] flex items-center justify-center font-bold text-[106px] mx-auto mb-6 rounded-lg"
                style={{
                    backgroundColor: paymentInformation?.orderType === 'collection' ? theme.secondaryColor : theme.primaryColor,
                    color: paymentInformation?.orderType === 'collection' ? theme.primaryColor : theme.secondaryColor,
                }}
            >
                {paymentInformation?.orderNumber}
            </div>

            <p 
                className="text-center mb-12 mx-[280px]" 
                dangerouslySetInnerHTML={{
                    __html: instructions
                }}
            ></p>

            <button
                className="btn btn-small-spacing btn-body mx-auto"
                onClick={() => {
                    restartKiosk();
                }}
            >
                Place another order
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" className="ml-2">
                    <g clipPath="url(#clip0_2174_1704)">
                        <path d="M24.2852 7.60697V0L14.1426 10.1426L24.2852 20.2853V12.6783C32.6782 12.6783 39.4992 19.4992 39.4992 27.8922C39.4992 35.4231 33.9968 41.6609 26.8209 42.878V48C36.8367 46.7575 44.5705 38.2377 44.5705 27.8922C44.5705 16.6846 35.4928 7.60697 24.2852 7.60697Z" fill="currentColor"/>
                        <path d="M9.07132 27.8922C9.07132 23.7084 10.7702 19.9049 13.5341 17.141L9.93344 13.5404C6.28209 17.2171 4 22.2884 4 27.8922C4 38.2377 11.7338 46.7575 21.7496 48V42.878C14.5737 41.6608 9.07132 35.4231 9.07132 27.8922Z" fill="currentColor"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_2174_1704">
                        <rect width="48" height="48" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
            </button>

            <LowPrinterPaperModal
                show={printerModal} 
                setShow={setPrinterModal}
            />
        </>
    );
}


export default Confirmed;