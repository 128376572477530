import React, { createContext, useContext, useState, ReactNode } from 'react';
import { ApiErrorInterface } from '../types/types';
// import { Filesystem, Directory } from '@capacitor/filesystem';

interface Toast {
    id: number;
    text: string;
    type: ToastTypes;
}

type ToastTypes = "voucher" | "loyalty" | "authenticated" | "productAdded" | "productRemove"

// Define the shape of your context
interface AppContextType {
    requestId: string | null;
    setRequestId: (id: string) => void;
    orderId: string | null;
    setOrderId: (id: string) => void;
    globalErrors: any;
    setGlobalErrors: (error: any) => void;
    addError: Function,
    clearErrors: Function,
    paymentInformation: {
        orderType: string
        orderNumber: string
        fullReceipt: string
        tableNumber: string
    }
    setPaymentInformation: React.Dispatch<React.SetStateAction<{
        orderType: string
        orderNumber: string
        fullReceipt: string
        tableNumber: string
    }>>
    toasts: Toast[],
    addToast: (text: string, type: ToastTypes) => void
}

interface GraphQLErrorItem {
    message: string;
    locations?: { line: number; column: number }[];
    path?: string[];
    extensions?: Record<string, any>;
}

type GraphQLError = GraphQLErrorItem[];

interface ErrorData {
    errorHandling: ApiErrorInterface
    error?: GraphQLError
}

// Create the AppContext with default values
const AppContext = createContext<AppContextType>({
    requestId: null,
    setRequestId: () => {},
    orderId: null,
    setOrderId: () => {},
    globalErrors: null,
    setGlobalErrors: () => {},
    addError: () => {},
    clearErrors: () => {},
    paymentInformation: {
        orderType: '',
        orderNumber: '',
        fullReceipt: '',
        tableNumber: ''
    },
    setPaymentInformation: () => {},
    toasts: [],
    addToast: () => {}
});

// Custom hook to use the AppContext
export const useAppContext = () => useContext(AppContext);

// Create a provider component
export const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [requestId, setRequestId] = useState<string | null>(null);
    const [orderId, setOrderId] = useState<string | null>(null);
    const [globalErrors, setGlobalErrors] = useState<any>([]);
    const [paymentInformation, setPaymentInformation] = useState({
        orderType: '',
        orderNumber: '',
        fullReceipt: '',
        tableNumber: ''
    });

    const [toasts, setToasts] = useState<{ id: number; text: string, type: ToastTypes }[]>([]);

    // Function to add a new toast
    const addToast = (text: string, type: ToastTypes) => {
        const id = Date.now(); // Unique ID for each toast
        setToasts((prevToasts) => [...prevToasts, { id, text, type }]);

        // Automatically remove toast after 5 seconds
        setTimeout(() => {
            removeToast(id);
        }, 5000);
    };

    // Function to remove a toast by ID
    const removeToast = (id: number) => {
        setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    };

    function addError(title: string, data: ErrorData, fallbackMessage?: string) {
        let newErrors: { id: number; type: string; message: string }[] = [];
    
        console.log(globalErrors, title, data, fallbackMessage);
    
        // Handle custom errorHandling messages
        if (data?.errorHandling) {
            if (Array.isArray(data.errorHandling.message)) {
                data.errorHandling.message.forEach((msg, msgIndex) => {
                    newErrors.push({
                        id: newErrors.length + (msgIndex + 1),
                        type: msg.type || 'error',
                        message: `${title} - ${msg.message || msg}`,
                    });
                });
            } else if (typeof data.errorHandling.message === 'string') {
                newErrors.push({
                    id: newErrors.length + 1,
                    type: 'error',
                    message: `${title} - ${data.errorHandling.message}`,
                });
            }
        }
    
        // Handle GraphQL errors
        else if (data?.error && Array.isArray(data.error)) {
            data.error.forEach((errorItem, index) => {
                newErrors.push({
                    id: newErrors.length + (index + 1),
                    type: 'error',
                    message: `${title} - ${errorItem.message}`,
                });
            });
        }
    
        // Fallback message if no specific error details provided
        else if (fallbackMessage) {
            newErrors.push({
                id: newErrors.length + 1,
                type: 'error',
                message: fallbackMessage,
            });
        }
    
        setGlobalErrors(newErrors);
        console.log(newErrors, "new errors");
    }

    function clearErrors() {
        setGlobalErrors([]);
    }

    // const readFilePath = async () => {
    //     try {
    //         // Read the file from external storage
    //         const contents = await Filesystem.readFile({
    //             path: 'Download/config.json', // Path relative to external storage
    //             directory: Directory.ExternalStorage // External storage directory
    //         });

    //         // Convert Blob or string to a JSON-parsable string
    //         let jsonString: string;

    //         if (typeof contents.data === 'string') {
    //             // If it's already a string, no need to convert
    //             jsonString = contents.data;
    //         } else if (contents.data instanceof Blob) {
    //             // If it's a Blob, convert it to string
    //             jsonString = await blobToString(contents.data);
    //         } else {
    //             throw new Error('Unexpected data format');
    //         }

    //         // Parse the JSON data
    //         const jsonData = JSON.parse(jsonString);
    //         console.log('Parsed JSON data:', jsonData);

    //         // Use the JSON data in your app logic
    //         return jsonData;

    //     } catch (error) {
    //         console.error('Error reading or parsing file:', error);
    //     }
    // };

    // Helper function to convert Blob to string
    // const blobToString = (blob: Blob): Promise<string> => {
    //     return new Promise((resolve, reject) => {
    //         const reader = new FileReader();
    //         reader.onload = () => resolve(reader.result as string);
    //         reader.onerror = reject;
    //         reader.readAsText(blob); // Read the Blob as a text string
    //     });
    // };

    // Call the function
    // readFilePath();



    // const downloadFile = async () => {
    //     const contents = await Filesystem.downloadFile({
    //         url: 'https://coopacstaging.co.uk/kiosk.json',
    //         path: 'https://coopacstaging.co.uk/kiosk.json'
    //     });
      
    //     console.log('data:', contents);
    // }

    // downloadFile();

    console.log(globalErrors, 'globalErrors');

    return (
        <AppContext.Provider 
            value={{ 
                requestId, 
                setRequestId,
                orderId,
                setOrderId,
                globalErrors, 
                setGlobalErrors,
                addError,
                clearErrors,
                paymentInformation, 
                setPaymentInformation,
                toasts,
                addToast
            }}
        >
            {children}
        </AppContext.Provider>
    );
};
