import { FC, } from 'react';
import { useGetCart } from '../../hooks/useGetCart';

import Item from './Item';

export const CartItems: FC = () => {
    const cart = useGetCart();

    return (
        <div className='mb-5 '>
            {cart && cart?.cart.items && cart?.cart.items.length ? (
                <div className='overflow-auto max-h-[640px]'>
                    {cart?.cart.items.map((item, index) => (
                        <Item
                            key={index}
                            item={item}
                            noMargBottom={index + 1 === cart?.cart.items.length}
                        />
                    ))}
                </div>
            ) : (
                <p className="font-bold text-4xl text-black bg-white p-10 rounded-md text-center">There are no items in your cart.</p>
            )}
        </div>
    );
}

export default CartItems;