import { FC, useEffect, useState } from "react";
import { useGetTheme } from "../../hooks/useGetTheme";

export const ToastLoyalty: FC<{text: string}> = ({text}) => {
    const theme = useGetTheme();

    const [isFadingOutLoyalty, setIsFadingOutLoyalty] = useState(false);
    const [showToast, setShowToast] = useState(false);

    useEffect(() => {
            setShowToast(true);

            // Start fade-out after 4.5 seconds
            const fadeTimer = setTimeout(() => setIsFadingOutLoyalty(true), 4000);

            // Hide completely after 5 seconds
            const hideTimer = setTimeout(() => {
                setShowToast(false);
                setIsFadingOutLoyalty(false); // Reset fade-out for next activation
            }, 5000);

            return () => {
                clearTimeout(fadeTimer);
                clearTimeout(hideTimer);
            };
    }, []);

    return (
        <>
            {showToast && (
                <div
                    className={`rounded-lg flex border border-slate-300 overflow-hidden mx-auto w-full shadow-lg mt-4 transition-all ${
                        isFadingOutLoyalty ? 'fade-out' : ''
                    }`}
                >
                    <div
                        className="px-6 py-5"
                        style={{
                            backgroundColor: theme.secondaryColor
                        }}
                    >
                        <svg
                            width="42"
                            height="40"
                            viewBox="0 0 42 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-10 h-10"
                        >
                            <path d="M21.0003 31.9792L33.8753 39.7501L30.4587 25.1042L41.8337 15.2501L26.8545 13.9792L21.0003 0.166748L15.1462 13.9792L0.166992 15.2501L11.542 25.1042L8.12533 39.7501L21.0003 31.9792Z" fill="white" />
                        </svg>
                    </div>

                    <div className="px-8 py-4 bg-white w-full flex items-center">
                        <p><strong>£{text}</strong> of reward points redeemed</p>
                    </div>
                </div>
            )}
        </>
    );
};

export default ToastLoyalty;
