import { FC } from 'react';
import { useGetTheme } from '../../hooks/useGetTheme';
import { useMenu } from '../../context/Menu';

export const MenuSearchTags: FC = () => {
    const theme = useGetTheme();

    const {
        searchQuery,
        searchTags,
        handleAllergyChange
    } = useMenu();

    if(!searchTags || (searchTags && !searchTags.length)) return null;

    const isSelected = (tag: string) => {
        if(searchQuery === tag) return true;
        return false;
    };

    return (
        <div className="flex mb-11 overflow-x-auto">
            <button
                className={`rounded-full py-4 px-8 text-lg font-bold leading-none mr-4 border break-keep ${isSelected('') ? '' : 'border-slate-400'}`}
                style={{
                    backgroundColor: isSelected('') ? theme.secondaryColor : '',
                    color: isSelected('') ? theme.primaryColor : ''
                }}
                onClick={() => {
                    handleAllergyChange('');
                }}
            >
                All
            </button>
            
            {searchTags.map((tag, index) => (
                <button
                    className={`rounded-full py-4 px-8 text-lg font-bold leading-none mr-4 border  whitespace-nowrap ${isSelected(tag) ? '' : 'border-slate-400'}`}
                    key={`search-tag-${index}`}
                    onClick={() => {
                        handleAllergyChange(tag);
                    }}
                    style={{
                        backgroundColor: isSelected(tag) ? theme.secondaryColor : '',
                        color: isSelected(tag) ? theme.primaryColor : ''
                    }}
                >
                    {tag}
                </button>
            ))}
        </div>
    );
};

export default MenuSearchTags;

