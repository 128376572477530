import { useQuery } from '@tanstack/react-query';

import fetchCategoriesProducts from '../api/fetchCategoriesProducts';
import { useGetCart } from './useGetCart';
import { useGetTheme } from './useGetTheme';

export const useGetCategories = () => {

    const theme = useGetTheme();
    const cart = useGetCart();

    const payload = {
        storeId: theme?.storeId,
        orderType: cart?.cart?.orderType === 'inside' ? 'eatIn' : 'collection'
    };

    const { data } = useQuery({
        queryKey: ['menu', payload],
        queryFn: () => fetchCategoriesProducts(payload),
        staleTime: (1000 * 60) * 60 * 1,
    });

    return data ?? [];
}