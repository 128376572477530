import { FC, useState } from 'react';

import RemoveItemModal from './RemoveItemModal';

interface RemoveItemButtonInterface {
    productId: number;
    productName: string
}

export const RemoveItemButton: FC<RemoveItemButtonInterface> = ({ productId, productName }) => {
    const [showCancelModal, setShowCancelModal] = useState(false);

    if(!productId) return null;

    return (
        <>
            <button 
                className="btn-danger rounded-r-lg w-[70px] flex items-center justify-center h-auto"
                onClick={() => {
                    setShowCancelModal(true);
                }}
            >
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.53341 25.3333L6.66675 23.4666L14.1334 16L6.66675 8.53329L8.53341 6.66663L16.0001 14.1333L23.4667 6.66663L25.3334 8.53329L17.8667 16L25.3334 23.4666L23.4667 25.3333L16.0001 17.8666L8.53341 25.3333Z" fill="white"/>
                </svg>
            </button>

            <RemoveItemModal
                productId={productId}
                productName={productName}
                show={showCancelModal} 
                setShow={setShowCancelModal}
            />
        </>
    );
};

export default RemoveItemButton;
