import { FC, useState } from "react";

import Modal from "../../Modal";
import { useGetTheme } from "../../../hooks/useGetTheme";
import { useGetTerminals } from "../../../hooks/useGetTerminals";

interface AdminModalInterface {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AdminModal: FC<AdminModalInterface> = ({ show, setShow }) => {
    const [pinCode, setPinCode] = useState('');
    const theme = useGetTheme();
    const terminals = useGetTerminals();

    console.log(terminals);

    return (
        <Modal
            show={show}
            setShow={setShow}
            onClose={()=> {
            }}
            width={840}
            fullHeight
        >
            <div className="bg-white overflow-auto">
                <p className="font-bold text-4xl mb-10 text-center border-b border-solid border-[#CBD5E1] pb-5">Settings</p>
                
                <div className="bg-gray-100 mb-10 p-10 text-2xl">
                    <div className="flex">
                        <p className="admin-setting-text font-bold">Logged in as:</p>
                        <p>Admin</p>
                    </div>

                    <div className="flex">
                        <p className="admin-setting-text font-bold">Dojo Terminal ID: </p>
                        <p>1422241234</p>
                    </div>

                    <div className="flex">
                        <p className="admin-setting-text font-bold">Kiosk ID: </p>
                        <p>TBC</p>
                    </div>

                    <div className="flex">
                        <p className="admin-setting-text font-bold">Kiosk URL: </p>
                        <p>https://s21preorder.fidelityportal.co.uk</p>
                    </div>

                    <div className="mt-5">
                        <p className="font-bold">Timeout Duration:</p>
                        <p className="flex">
                            <span className="admin-setting-text">Store: 5 Minutes</span>
                        Cart: 5 Minutes
                        </p>
                    </div>
                </div>

                <div className="flex space-x-5">
                    <button
                        className="btn btn-primary w-1/2"
                        style={{
                            background: theme?.primaryColor ? theme?.primaryColor : '#000',
                            color: '#fff'
                        }}
                    >Change Pin</button>

                    <button
                        className="btn btn-primary w-1/2"
                        style={{
                            background: theme?.secondaryColor ? theme?.secondaryColor : '#000',
                            color: '#fff'
                        }}
                    >
                        Reload Config 
                        <svg className="w-8 h-8 ml-5" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#fff"><path d="M480-160q-134 0-227-93t-93-227q0-134 93-227t227-93q69 0 132 28.5T720-690v-110h80v280H520v-80h168q-32-56-87.5-88T480-720q-100 0-170 70t-70 170q0 100 70 170t170 70q77 0 139-44t87-116h84q-28 106-114 173t-196 67Z"/></svg>
                    </button>
                </div>

                <div className="h-1 border-b border-[#CBD5E1] my-10"></div>

                <div className="mb-5">
                    <p className="font-bold text-2xl text-left border-b border-solid border-[#CBD5E1] pb-4 mb-5">Kiosk ID</p>
                    <p><strong>Enter Kiosk ID</strong></p>

                    <div className="flex items-center">
                        <input 
                            type="text" 
                            name="kioskid" 
                            placeholder="Enter Kiosk ID" 
                            className="border border-[#CBD5E1] p-5 mt-5 w-full mb-5"
                        />
                        <button
                            className="btn btn-primary btn-square btn-small min-w-[150px] w-[100px]"
                            onClick={() => {
                                
                            }}
                            style={{
                                background: theme?.secondaryColor ? theme?.secondaryColor : '#000',
                                color: '#fff'
                            }}
                        >Save</button>
                    </div>
                </div>

                <div className="mb-5">
                    <p className="font-bold text-2xl text-left border-b border-solid border-[#CBD5E1] pb-4 mb-5">Dojo Terminal</p>
                    <p><strong>Select Dojo Terminal</strong></p>

                    <div className="flex items-center">
                        {terminals?.terminals ? (
                            <select className="border border-[#CBD5E1] p-5 mt-5 w-full mb-5">
                                <option>Please select...</option>
                                {terminals?.terminals.map((terminal)=> {
                                    return (
                                        <option value={terminal.tid}>{terminal.tid}</option>
                                    )
                                })}
                            </select>
                        ) : null}
                        
                        <button
                            className="btn btn-primary btn-square btn-small min-w-[150px] w-[100px]"
                            onClick={() => {
                                
                            }}
                            style={{
                                background: theme?.secondaryColor ? theme?.secondaryColor : '#000',
                                color: '#fff'
                            }}
                        >Save</button>
                    </div>
                </div>

                <div className="bg-red-200 p-10 text-center">
                    <p className="font-bold text-2xl mb-5 text-center">The Danger Zone</p>
                    <p className="mb-10">If you need to reset the app, please press the button below.</p>
                
                    <button
                        className="btn btn-primary w-1/2 bg-red-400 text-white mx-auto"
                    >Reset Kiosk</button>

                    <p className="mt-10">*Please note, once this action has been performed, it cannot be undone.</p>
                </div>
                
            </div>
        </Modal>
    )
};

export default AdminModal;
